import CacheCleaner from "./CacheCleaner"
import SeasonCleaner from "./SeasonCleaner"

export default class ContractConfigCleaner extends CacheCleaner {

	async onMutation(contractconfig_ids, columns) {
		await this.init()

		return this.db().t('contract_config')
		.then(table => {
			return table.where('contractconfig_id').anyOf(contractconfig_ids).toArray()
		})
		.then(configs =>{
			const season_ids = configs.map(config => config.contractconfig_season);
			return this.onMutationSeason(season_ids, columns);
		})
	}

	// Mise à jour du caches sur les saison en lien des contract_config
	async onMutationSeason(contracts_id, columns) {
		await SeasonCleaner.inst().onMutation(contracts_id, columns)
	}
}
